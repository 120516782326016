const messages = {
  en: {
    translations: {
      contactId: "Id",
      contactName: "Name",
      contactNumber: "Number",
      tasks: {
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure you want to delete this task?",
        },
      },
      kanban: {
        user: "User",
        status: "Status"
      },
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Log in!!!.",
          fail: "Error creating user. Check the information provided.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Sign up",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Login",
          register: "Register now!",
        },
      },
      plans: {
        form: {
          name: "Name",
          users: "Users",
          connections: "Connections",
          campaigns: "Campaigns",
          schedules: "Schedules",
          enabled: "Enabled",
          disabled: "Disabled",
          clear: "Cancel",
          delete: "Delete",
          save: "Save",
          yes: "Yes",
          no: "No",
          money: "R$",
        },
      },
      companies: {
        title: "Register Company",
        form: {
          name: "Company Name",
          plan: "Plan",
          token: "Token",
          submit: "Register",
          success: "Company created successfully!",
        },
      },
      auth: {
        toasts: {
          success: "Login successful!",
        },
        token: "Token",
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Atendimentos hoje: ",
          },
        },
        infoQueuesModal: {
          title: "Info Queues",
          table: {
            queueColumn: "Queue",
            queueQuantityColumn: "Qt. atendimentos",
          },
        },
        ticketsHappeningsNotContinuedModal: {
          title: "Atendimentos não continuados",
          table: {
            serviceColumn: "Atendimento",
            attendantColumn: "Atendant",
            contactColumn: "Contact",
            numberColumn: "Number",
            queueColumn: "Queue",
            idleTimeColumn: "Idle Time",
            idleTimeColumnComplement: "minutes ago",
            lastConversationDateColumn: "Last conversation date",
          },
          pagination: {
            info: "Page",
            buttons: {
              previous: "Previous",
              next: "Next",
            },
          },
          tooltipTitle: "Peek at conversation",
        },
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted with success!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? This action cannot be reversed.",
          disconnectTitle: "Disconnect",
          disconnectMessage:
            "Are you sure? You will need to scan the QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "disconnect",
          tryAgain: "Try again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connecting",
        },
        toolTips: {
          disconnected: {
            title: "WhatsApp login failed",
            content:
              "Make sure your phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code to be scanned",
            content:
              "Click the 'QR CODE' button and scan the QR Code with your phone to log in",
          },
          connected: {
            title: "Connection established!",
          },
          timeout: {
            title: "Connection with the phone was lost",
            content:
              "Make sure your phone is connected to the internet and WhatsApp is open, or click the 'Disconnect' button to get a new QR Code",
          },
        },
        table: {
          id: "Id",
          name: "Name",
          status: "Status",
          lastUpdate: "Last Updated",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        tabs: {
          general: "General",
          messages: "Messages",
          assessments: "Assessments",
          integrations: "Integrations",
          schedules: "Office Hours",
        },
        form: {
          name: "Name",
          default: "Default",
          sendIdQueue: "Queue",
          timeSendQueue: "Redirect to queue in X minutes",
          queueRedirection: "Queue Redirect",
          outOfHoursMessage: "Out of Hours Message",
          queueRedirectionDesc:
            "Select a queue to redirect contacts who do not have a queue",
          prompt: "Prompt",
          //maxUseBotQueues: "Send bot x times",
          //timeUseBotQueues: "Interval in minutes between bot sends",
          expiresTicket: "Close open chats after x minutes",
          expiresInactiveMessage: "Inactivity end message",
          greetingMessage: "Greeting message",
          complationMessage: "Completion message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      qrCode: {
        message: "Scan the QrCode to start the session",
      },
      reports: {
        title: "Reports",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted successfully!",
          deletedAll: "All contacts deleted successfully!",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Delete",
          deleteAllTitle: "Delete All",
          importTitle: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          deleteAllMessage:
            "Are you sure you want to delete all contacts? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
          export: "Export Contacts",
          delete: "Delete All Contacts",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
      },
      queueIntegrationModal: {
        title: {
          add: "Add Project",
          edit: "Edit Project",
        },
        form: {
          id: "ID",
          type: "Type",
          name: "Name",
          projectName: "Project Name",
          language: "Language",
          jsonContent: "JsonContent",
          urlN8N: "Api URL",
          typebotUrlServer: "Server URL",
          typebotSlug: "Typebot - Slug",
          typebotToken: "Typebot - Token",
          typebotExpires: "Time in minutes to expire a conversation",
          typebotKeywordFinish: "Word to end the ticket",
          typebotKeywordRestart: "Word to restart the flow",
          typebotKeywordStart: "Word to start the flow",
          typebotRestartMessage: "Message when restarting the conversation",
          typebotUnknownMessage: "Invalid option message",
          typebotDelayMessage: "Interval (ms) between messages",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
          test: "Test Bot",
        },
        messages: {
          testSuccess: "Integration tested successfully!",
          addSuccess: "Integration added successfully!",
          editSuccess: "Integration edited successfully.",
        },
      },
      sideMenu: {
        name: "Initial Side Menu",
        note: "If enabled, the side menu will start closed",
        options: {
          enabled: "Open",
          disabled: "Closed",
        },
      },
      promptModal: {
        form: {
          name: "Name",
          prompt: "Prompt",
          voice: "Voice",
          max_tokens: "Maximum Tokens in Response",
          temperature: "Temperature",
          apikey: "API Key",
          max_messages: "Maximum Messages in History",
          voiceKey: "Voice API Key",
          voiceRegion: "Voice Region",
        },
        success: "Prompt saved successfully!",
        title: {
          add: "Add Prompt",
          edit: "Edit Prompt",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      prompts: {
        title: "Prompts",
        table: {
          name: "Name",
          queue: "Sector/Queue",
          max_tokens: "Maximum Response Tokens",
          actions: "Actions",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? This action cannot be reversed!",
        },
        buttons: {
          add: "Add Prompt",
        },
      },
      contactModal: {
        title: {
          add: "Add Contact",
          edit: "Edit Contact",
        },
        form: {
          mainInfo: "Contact Data",
          extraInfo: "Informadditional actions",
          name: "Name",
          number: "Whatsapp Number",
          email: "Email",
          extraName: "Field Name",
          extraValue: "Value",
          whatsapp: "Source Connection: ",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add Queue",
          edit: "Edit Queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
          complationMessage: "Completion Message",
          outOfHoursMessage: "Out of Hours Message",
          ratingMessage: "Evaluation message",
          token: "Token",
          orderQueue: "Queue order (Bot)",
          hasRoulette: "Has Roulette",
          integrationId: "Integration",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
          whatsapp: "Default Connection",
          allTicket: "View All [Queue]",
          allTicketEnabled: "Enabled",
          allTicketDesabled: "Disabled",
          tokenWhats: "Firing Token",
          userWhats: "User WhatsApp",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      rouletteModal: {
        title: {
          add: "Add roulette",
          edit: "Edit roulette",
        },
        form: {
          lastSequence: "Last Sequence",
          queueId: "Queue",
          sequence: "Sequence",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        rouletteOptions: {
          id: "id",
          user: "User",
          sequence: "Sequence",
          actions: "Actions",
          toasts: {
            warning: "Select a user and a sequence to save",
            warningOption: "Finalize the configuration of the current user",
          },
        },
        success: "Roulette saved successfully.",
      },
      scheduleModal: {
        title: {
          add: "New Schedule",
          edit: "Edit Schedule",
        },
        form: {
          body: "Message",
          contact: "Contact",
          sendAt: "Schedule Date",
          sentAt: "Send Date",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Schedule saved successfully.",
      },
      tagModal: {
        title: {
          add: "New Tag",
          edit: "Edit Tag",
        },
        form: {
          name: "Name",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Tag saved successfully.",
      },
      productModal: {
        title: {
          add: "New Product",
          edit: "Edit Product",
        },
        form: {
          name: "Name",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Product saved successfully.",
      },
      originModal: {
        title: {
          add: "New Origin",
          edit: "Edit Origin",
        },
        form: {
          name: "Name",
          color: "Color",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Origin saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      uploads: {
        titles: {
          titleUploadMsgDragDrop: "DRAG AND DROP FILES INTO THE FIELD BELOW",
          titleFileList: "List of file(s)",
        },
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The service you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Open" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search for service and messages",
        },
        buttons: {
          showAll: "All",
          notResponse: "S/R",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldQueuePlaceholder: "Select a queue",
        noOptions: "No users found with that name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Waiting",
        assignedHeader: "Serving",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No service found with that status or search term",
        buttons: {
          accept: "Accept",
          closed: "Finalize",
          reopen: "Reopen",
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Services",
          quickMessages: "Quick Replies",
          contacts: "Contacts",
          queues: "Queues & Chatbot",
          tags: "Tags",
          products: "Products",
          origins: "Origins",
          administration: "Administration",
          users: "Users",
          roulettes: "Roulettes",
          settings: "Settings",
          helps: "Help",
          messagesAPI: "API",
          schedules: "Schedules",
          campaigns: "Campaigns",
          annoucements: "Informative",
          chats: "Internal Chat",
          financial: "Financial",
          files: "File List",
          prompts: "Open.Ai",
          queueIntegration: "Integrations",
          ticketImport: "Import Tickets",
        },
        appBar: {
          notRegister: "No notifications",
          user: {
            profile: "Profile",
            logout: "Logout",
          },
        },
      },
      queueIntegration: {
        title: "Integrations",
        table: {
          id: "ID",
          type: "Type",
          name: "Name",
          projectName: "Project Name",
          language: "Language",
          lastUpdate: "Last Update",
          actions: "Actions",
        },
        buttons: {
          add: "Add Project",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? This action cannot be reversed! and will be removed from the linked queues and connections",
        },
      },
      files: {
        title: "File List",
        table: {
          name: "Name",
          contacts: "Contacts",
          actions: "Action",
        },
        toasts: {
          deleted: "List deleted successfully!",
          deletedAll: "All lists deleted successfully!",
        },
        buttons: {
          add: "Add",
          deleteAll: "Delete All",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteAllTitle: "Delete All",
          deleteMessage: "Are you sure you want to delete this list?",
          deleteAllMessage: "Are you sure you want to delete all lists?",
        },
      },
      messagesAPI: {
        title: "API",
        textMessage: {
          number: "Number",
          body: "Message",
          token: "Registered token",
        },
        mediaMessage: {
          number: "Number",
          body: "File name",
          media: "File",
          token: "Registered token",
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      quickMessages: {
        title: "Quick Replies",
        searchPlaceholder: "Search...",
        noAttachment: "No attachment",
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "This action is irreversible! Do you want to continue?",
        },
        buttons: {
          add: "Add",
          attach: "Attach File",
          cancel: "Cancel",
          edit: "Edit",
        },
        toasts: {
          success: "Shortcut added successfully!",
          deleted: "Shortcut removed successfully!",
        },
        dialog: {
          title: "Quick Message",
          shortcode: "Shortcut",
          message: "Reply",
          save: "Save",
          cancel: "Cancel",
          general: "Allow editing",
          add: "Add",
          edit: "Edit",
          view: "Allow viewing",
        },
        table: {
          shortcode: "Shortcut",
          message: "Message",
          actions: "Actions",
          mediaName: "File Name",
          status: "Status",
        },
      },
      messageVariablesPicker: {
        label: "Available Variables",
        vars: {
          contactFirstName: "FirstName",
          contactName: "Name",
          greeting: "Greeting",
          protocolNumber: "Protocol",
          date: "Date",
          hour: "Time",
        },
      },
      campaignsUsersContactLists: {
        title: "Contact Lists (User)",
      },
      contactLists: {
        title: "Contact Lists",
        table: {
          name: "Name",
          contacts: "Contacts",
          actions: "Actions",
        },
        buttons: {
          add: "New List",
        },
        dialog: {
          name: "Name",
          company: "Company",
          okEdit: "Edit",
          okAdd: "Add",
          add: "Add",
          edit: "Edit",
          cancel: "Cancel",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be reverted.",
        },
        toasts: {
          deleted: "Record deleted",
        },
      },
      contactListItems: {
        title: "Contacts",
        searchPlaceholder: "Search",
        buttons: {
          add: "New",
          lists: "Lists",
          import: "Import",
        },
        dialog: {
          name: "Name",
          number: "Number",
          whatsapp: "Whatsapp",
          email: "Email",
          okEdit: "Edit",
          okAdd: "Add",
          add: "Add",
          edit: "Edit",
          cancel: "Cancel",
        },
        table: {
          name: "Name",
          number: "Number",
          whatsapp: "Whatsapp",
          email: "Email",
          actions: "Actions",
          ticket: "Ticket",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be reversed.",
          importMessage:
            "Do you want to import contacts from this spreadsheet? ",
          importTitlte: "Import",
        },
        toasts: {
          deleted: "Record deleted",
        },
      },
      campaignsUsers: {
        title: "Campaigns (Users)",
      },
      campaigns: {
        title: "Campaigns",
        searchPlaceholder: "Search",
        buttons: {
          add: "New Campaign",
          contactLists: "Contact Lists",
        },
        table: {
          name: "Name",
          whatsapp: "Connection",
          contactList: "Contact List",
          status: "Status",
          scheduledAt: "Scheduled",
          completedAt: "Completed",
          confirmation: "Confirmation",
          actions: "Actions",
        },
        dialog: {
          new: "New Campaign",
          update: "Edit Campaign",
          readonly: "View Only",
          form: {
            name: "Name",
            message1: "Message 1",
            message2: "Message 2",
            message3: "Message 3",
            message4: "Message 4",
            message5: "Message 5",
            confirmationMessage1: "Confirmation Message 1",
            confirmationMessage2: "Confirmation Message 2",
            confirmationMessage3: "Confirmation Message 3",
            confirmationMessage4: "Confirmation Message 4",
            confirmationMessage5: "Confirmation Message 5",
            messagePlaceholder: "Message Content",
            whatsapp: "Connection",
            status: "Status",
            scheduledAt: "Scheduling",
            confirmation: "Confirmation",
            contactList: "Contact List",
            tagList: "Tag List",
            productList: "Product List",
            fileList: "File List",
          },
          buttons: {
            add: "Add",
            edit: "Update",
            okadd: "Ok",
            cancel: "Cancel Triggers",
            restart: "Restart Triggers",
            close: "Close",
            attach: "Attach File",
          },
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be reversed.",
        },
        toasts: {
          success: "Operation performed successfully",
          cancel: "Campaign canceled",
          restart: "Campaign restarted",
          deleted: "Record deleted",
        },
      },
      announcements: {
        active: "Active",
        inactive: "Inactive",
        title: "Newsletters",
        searchPlaceholder: "Search",
        buttons: {
          add: "New Newsletter",
          contactLists: "Newsletter Lists",
        },
        table: {
          priority: "Priority",
          title: "Title",
          text: "Text",
          mediaName: "File",
          status: "Status",
          actions: "Actions",
        },
        dialog: {
          edit: "Edit Newsletter",
          add: "New Newsletter",
          update: "Edit Newsletter",
          readonly: "View Only",
          form: {
            priority: "Priority",
            title: "Title",
            text: "Text",
            mediaPath: "File",
            status: "Status",
          },
          buttons: {
            add: "Add",
            edit: "Update",
            okadd: "Ok",
            cancel: "Cancel",
            close: "Close",
            attach: "Attach File",
          },
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "This action cannot be reversed.",
        },
        toasts: {
          success: "Operation performed successfully",
          deleted: "Record deleted",
        },
      },
      campaignsUsersCampaignsConfig: {
        title: "Campaign Settings (Users)",
      },
      campaignsConfig: {
        title: "Campaign Settings",
      },
      queues: {
        title: "Queues & Chatbot",
        table: {
          id: "ID",
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
          orderQueue: "Order queue (bot)",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? This action cannot be reversed! The queue's requests will continue to exist, but will no longer have any queues assigned to them.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      users: {
        title: "Users",
        table: {
          id: "ID",
          name: "Name",
          email: "Email",
          profile: "Profile",
          sector: "Sector",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted successfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. This user's open calls will be moved to the queue.",
        },
      },
      roulettes: {
        title: "Roulettes",
        table: {
          id: "ID",
          lastSequence: "Last Sequence",
          queueId: "Queue",
          sequence: "Sequence",
          queueName: "Queue Name",
          actions: "Actions",
        },
        buttons: {
          add: "Add roulette",
        },
        toasts: {
          deleted: "Roulette deleted successfully.",
        },
        confirmationModal: {
          name: "Roulette",
          deleteTitle: "Delete",
          deleteMessage:
            "All roulette data will be lost. Do you really want to proceed with this action?.",
        },
      },
      helps: {
        title: "Help Center",
      },
      schedules: {
        title: "Schedules",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Schedule?",
          deleteMessage: "This action cannot be reversed.",
        },
        table: {
          contact: "Contact",
          body: "Message",
          sendAt: "Schedule Date",
          sentAt: "Sent Date",
          status: "Status",
          actions: "Actions",
        },
        buttons: {
          add: "New Schedule",
        },
        toasts: {
          deleted: "Schedule deleted successfully.",
        },
      },
      tags: {
        title: "Tags",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Tag?",
          deleteMessage: "This action cannot be undone.",
          deleteAllMessage: "Are you sure you want to delete all Tags?",
          deleteAllTitle: "Delete All",
        },
        table: {
          id: "ID",
          name: "Name",
          color: "Color",
          tickets: "Tag Records",
          actions: "Actions",
        },
        buttons: {
          add: "New Tag",
          deleteAll: "Delete All",
        },
        toasts: {
          deletedAll: "All Tags successfully deleted!",
          deleted: "Tag successfully deleted.",
        },
      },
      products: {
        title: "Products",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Product?",
          deleteMessage: "This action cannot be undone.",
          deleteAllMessage: "Are you sure you want to delete all Products?",
          deleteAllTitle: "Delete All",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Records",
          actions: "Actions",
        },
        buttons: {
          add: "New Product",
          deleteAll: "Delete All",
        },
        toasts: {
          deletedAll: "All Products have been successfully deleted!",
          deleted: "Product successfully deleted.",
        },
      },
      origins: {
        title: "Origins",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Origin?",
          deleteMessage: "This action cannot be reversed.",
          deleteAllMessage: "Are you sure you want to delete all Origins?",
          deleteAllTitle: "Delete All",
        },
        table: {
          name: "Name",
          color: "Color",
          tickets: "Records",
          actions: "Actions",
        },
        buttons: {
          add: "New Source",
          deleteAll: "Delete All",
        },
        toasts: {
          deletedAll: "All Sources have been deleted successfully!",
          deleted: "Source deleted successfully.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User Creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
        },
        messagesList: {
          header: {
            assignedTo: "Assigned To:",
            buttons: {
              return: "Return",
              returnNotMessage: "Return without completion message",
              resolve: "Resolve",
              reopen: "Reopen",
              accept: "Accept",
            },
          },
        },
        messagesInput: {
          placeholderOpen: "Enter a message",
          placeholderClosed: "Reopen or accept this ticket to send a message.",
          signMessage: "Sign",
        },
        contactDrawer: {
          header: "Contact details",
          buttons: {
            edit: "Edit contact",
          },
          extraInfo: "Other information",
        },
        fileModal: {
          title: {
            add: "Add file list",
            edit: "Edit file list",
          },
          buttons: {
            okAdd: "Save",
            okEdit: "Edit",
            cancel: "Cancel",
            fileOptions: "Add file",
          },
          form: {
            name: "File list name",
            message: "List details",
            fileOptions: "File list",
            extraName: "Message to send with file",
            extraValue: "Option value",
          },
          success: "File list saved successfully!",
        },
        ticketOptionsMenu: {
          schedule: "Appointment",
          delete: "Delete",
          transfer: "Transfer",
          registerAppointment: "Contact Notes",
          appointmentsModal: {
            title: "Contact Notes",
            textarea: "Note",
            placeholder: "Enter the information you want to register here",
          },
          confirmationModal: {
            title: "Delete ticket",
            titleFrom: "from contact",
            message:
              "Attention! All messages related to the ticket will be lost.",
          },
          buttons: {
            delete: "Delete",
            cancel: "Cancel",
          },
        },
        confirmationModal: {
          buttons: {
            confirm: "Ok",
            cancel: "Cancel",
          },
        },
        messageOptionsMenu: {
          delete: "Delete",
          reply: "Reply",
          confirmationModal: {
            title: "Delete message?",
            message: "This action cannot be reversed.",
          },
        },
        backendErrors: {
          ERR_NO_OTHER_WHATSAPP: "There must be at least one default WhatsApp.",
          ERR_NO_DEF_WAPP_FOUND:
            "No default WhatsApp found. Please check the connections page.",
          ERR_WAPP_NOT_INITIALIZED:
            "This WhatsApp session has not been initialized. Please check the connections page.",
          ERR_WAPP_CHECK_CONTACT:
            "Unable to verify WhatsApp contact. Please check the connections page.",
          ERR_WAPP_INVALID_CONTACT: "This is not a valid WhatsApp number.",
          ERR_WAPP_DOWNLOAD_MEDIA:
            "Unable to download WhatsApp media. Please check the connections page.",
          ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
          ERR_SENDING_WAPP_MSG:
            "Error sending WhatsApp message. Please check the connections page.",
          ERR_DELETE_WAPP_MSG: "Unable to delete WhatsApp message.",
          ERR_OTHER_OPEN_TICKET:
            "There is already an open ticket for this contact.",
          ERR_SESSION_EXPIRED: "Session expired. Please sign in.",
          ERR_USER_CREATION_DISABLED:
            "User creation has been disabled by the administrator.",
          ERR_NO_PERMISSION:
            "You do not have permission to access this feature.",
          ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
          ERR_NO_SETTING_FOUND: "No settings found with this ID.",
          ERR_NO_CONTACT_FOUND: "No contacts found with this ID.",
          ERR_NO_TICKET_FOUND: "No tickets found with this ID.",
          ERR_NO_USER_FOUND: "No users found with this ID.",
          ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
          ERR_CREATING_MESSAGE: "Error creating message in database.",
          ERR_CREATING_TICKET: "Error creating ticket in the database.",
          ERR_FETCH_WAPP_MSG:
            "Error fetching message from WhatsApp, maybe it is too old.",
          ERR_QUEUE_COLOR_ALREADY_EXISTS:
            "This color is already in use, please choose another one.",
          ERR_WAPP_GREETING_REQUIRED:
            "The greeting message is mandatory when there is more than one queue.",
        },
      },
    },
  },
};

export { messages };
